import { only } from '~/utils/mapping-filters.js'

const mapEntry = (entry) => {
  return {
    title: entry.title,
    metadata: only(entry.metadata),
    masthead: only(entry.masthead)
  }
}

export const mapCraft = (craft) => {
  return {
    entry: mapEntry(craft.entry)
  }
}