import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Metadata from '~/components/Metadata'
import Masthead from '~/components/Masthead'
import BigMomentsBlock from '~/components/BigMomentsBlock'

import { Spacer, DripPattern } from '~/styles/components'

import { mapCraft } from './mappings'

const BigMomentsArchiveTemplate = ({ data }) => {
  const { entry } = mapCraft(data.craft)
  const { masthead, metadata } = entry
  
  return (
    <div>
      <Metadata
        title="Big moments"
        {...metadata}
      />
      <DripPattern
        color="cream"
        top="300px"
      />
      <Masthead
        {...masthead}
      />
      <BigMomentsBlock />
      <Spacer size="md" />
      <Spacer size="xs" />
    </div>
  )
}

BigMomentsArchiveTemplate.propTypes = {
  data: PropTypes.object
}

export default BigMomentsArchiveTemplate

export const query = graphql`
query BigMomentsArchiveTemplateQuery($id: Craft_QueryArgument) {
  craft {
    entry(id: [$id]) {
      ... on Craft_bigMomentsArchive_bigMomentsArchive_Entry {
        metadata {
          ... on Craft_metadata_BlockType {
            title: metadataTitle
            description: metadataDescription
            keywords: metadataKeywords
            noFollow: metadataNoFollow
            noIndex: metadataNoIndex
          }
        }
        masthead {
          ... on Craft_masthead_BlockType {
            heading
            subheading
            body
          }
        }
      }
    }
  }
}
`